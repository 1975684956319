import { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { showModal } from "../../redux/actions/modal";
import {
  getAggregators,
  getAggregatorDrilldown,
} from "../../redux/actions/aggregators";

// Tabs
import FinancialStats from "./TabComponents/FinancialStats";
import AggregatorProfile from "./TabComponents/AggregatorProfile";
import Funders from "./TabComponents/Funders";
import Partners from "./TabComponents/Partners";
import AggregatorProfileSettings from "./TabComponents/AggregatorProfileSettings";
import TermsAndConditions from "./TabComponents/TermsAndConditions/TermsAndConditions";
// import Settings from "./TabComponents/Settings";

// Custom Components
import { HeaderWithButton } from "../PageHeaders/PageHeaders";

// UI Components
import Filter from "./Filter/Filter";
import Tabs from "../Tabs/Tabs";

import AllWallet from "../Wallet/AllWallet";
import { getAggregatorProductTypes } from "../../custom-hooks/useAggregatorProductType";
import { appInsights } from "../../config/appInsights";

// Styles
import "./ManageUsers.css";
import { RootState } from "../../redux/store";
import AggregatorFinancialStats from "./TabComponents/AggregatorStats/aggregatorStats";
import useAggregatorProductDetails from "../../custom-hooks/useAggregatorProductsDetails";
import useAggregatorActiveCurrencies from "../../custom-hooks/useAggregatorActiveCurrencies";
import useTransactionsOverview from "../../custom-hooks/useTransactionsOverview";
import useLoanCount from "../../custom-hooks/useLoanCount";
import useTotalOutstandingLoans from "../../custom-hooks/useTotalOutstandingLoans";

type TState = {
  ecosystemData: any;
  ecosystemName: string;
  aggregatorProductTypes: object | null;
  activeTab: string;
  tabs: {
    name: string;
    id: string;
    icon: string;
    identifier: string;
  }[];
};

const ManageUsers = (props: any) => {
  const [currentCurrency, setCurrentCurrency] = useState<any>("NGN");
  const [activeProductName, setActiveProductName] = useState<string>("Spark")
  const [state, setState] = useState<TState>({
    ecosystemData: {},
    ecosystemName: "",
    aggregatorProductTypes: null,
    activeTab: "finance",
    tabs: [
      {
        name: "Financial Statistics",
        id: "finance",
        icon: "fas fa-chart-line",
        identifier: "parentTab",
      },
      {
        name: "Aggregator Profile",
        id: "agg-profile",
        icon: "fas fa-building",
        identifier: "parentTab",
      },
      {
        name: "Aggregator Funders",
        id: "funders",
        icon: "fas fa-users",
        identifier: "parentTab",
      },
      {
        name: "Aggregator Borrowers",
        id: "borrowers",
        icon: "fas fa-users",
        identifier: "parentTab",
      },
      {
        name: "Profile Settings",
        id: "settings",
        icon: "fas fa-cog",
        identifier: "parentTab",
      },
      {
        name: "Wallet",
        id: "wallet",
        icon: "fas fa-credit-card",
        identifier: "parentTab",
      },
      {
        name: "Aggregator",
        id: "aggregator-setting",
        icon: "fas fa-building",
        identifier: "parentTab",
      },
      {
        name: "Terms and Conditions",
        id: "termsandconditions",
        icon: "fas fa-book",
        identifier: "parentTab",
      },
    ],
  });

  const { isAuthenticated } = useSelector(
    (state: RootState) => state?.authReducer
  );
  const { currentAggregator, allAggregators } = useSelector(
    (state: RootState) => state?.aggregatorsReducer
  );
  const { aggregatorStats } = useSelector(
    (state: RootState) => state?.statsReducer
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const modalHandler = (modalData: any) => {
    dispatch(showModal(modalData));
  };

  const handleGetAggregatorProductTypes = async (id: number) => {
    try {
      const response = await getAggregatorProductTypes(id);
      if (response?.data?.status === true) {
        setState((prev) => {
          return {
            ...prev,
            aggregatorProductTypes: response,
          };
        });
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: {
              type: "failure",
              message: response?.data?.message,
            },
          })
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ManageUsers.js",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        })
      );
    }
  };

  const onView = (item: any) => {
    handleGetAggregatorProductTypes(item.aggregator_id);
    let mode = item.mode;

    const ecosystemSelector = document.querySelector(
      ".ecosystem-selector"
    )?.classList;

    const tabularDataset =
      document.querySelector(".tabular-dataset")?.classList;

    if (mode === "view") {
      setState((prev) => {
        return {
          ...prev,
          ecosystemData: item,
          ecosystemName: item.biz_name,
        };
      });

      setTimeout(() => {
        ecosystemSelector?.remove("speed-1x", "fadeInRight");
        ecosystemSelector?.remove("fadeInRight");
        ecosystemSelector?.add("speed-1x", "fadeOutLeft");

        setTimeout(() => {
          ecosystemSelector?.add("hidden");
          ecosystemSelector?.remove("speed-1x", "fadeOutLeft");
          tabularDataset?.remove("hidden");
          dispatch(getAggregatorDrilldown(item.aggregator_id));
        }, 500);
      }, 500);
    } else if (mode === "close") {
      setTimeout(() => {
        tabularDataset?.remove("speed-1x", "fadeInRight");
        tabularDataset?.add("speed-1x", "fadeOutRight");

        setTimeout(() => {
          tabularDataset?.add("hidden");
          tabularDataset?.remove("speed-1x", "fadeOutRight");
          ecosystemSelector?.remove("hidden");
          ecosystemSelector?.add("speed-1x", "fadeInLeft");
        }, 500);
      }, 500);
    }
  };

  const closeView = (e: any) => {
    let mode = e.currentTarget.dataset.mode;

    const ecosystemSelector = document.querySelector(
      ".ecosystem-selector"
    )?.classList;

    const tabularDataset =
      document.querySelector(".tabular-dataset")?.classList;

    if (mode === "close") {
      setTimeout(() => {
        tabularDataset?.remove("speed-1x", "fadeInRight");
        tabularDataset?.add("speed-1x", "fadeOutRight");

        setTimeout(() => {
          tabularDataset?.add("hidden");
          tabularDataset?.remove("speed-1x", "fadeOutRight");
          ecosystemSelector?.remove("hidden");
          ecosystemSelector?.add("speed-1x", "fadeInLeft");
        }, 500);
      }, 500);
    }
  };

  const toggleTabContent = (content: any) => {
    setState((prev) => {
      return {
        ...prev,
        activeTab: content,
      };
    });
  };

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/login");
    }

    dispatch(getAggregators());

    appInsights.trackPageView({
      name: "Manage Aggregators - (ManageUsers.js)",
      isLoggedIn: true,
    });
  }, []);

  const {
    data: aggregatorCurrencies,
    status: aggregatorCurrenciesStatus,
    error: aggregatorCurrenciesError,
    isLoading: isLoadingCurrencies,
  } = useAggregatorActiveCurrencies({
    aggregator_id: state.ecosystemData.aggregator_id,
  });

  const {
    data: productDetails,
    status: productStatus,
    error: productError,
  } = useAggregatorProductDetails({
    aggregatorId: state.ecosystemData.aggregator_id,
  });

  // const activeProductName = productDetails
  //   ?.filter(({ is_default }: { is_default: boolean }) => {
  //     return is_default === true;
  //   })
  //   .map(({ product_display_name }: { product_display_name: string }) => {
  //     return product_display_name;
  //   })
  //   .join();

  const {
    data: transactionsOverview,
    status: trasactionOverviewStatus,
    error: transactionsError,
    isLoading: isLoadingTransactions,
  } = useTransactionsOverview({
    currentCurrencyValue: currentCurrency,
    activeProductName: activeProductName,
    aggregator_id: state.ecosystemData.aggregator_id,
  });

  const {
    data: loanCount,
    status: loanCountStatus,
    error: loanCountError,
    isLoading: isLoadingLoanCount,
  } = useLoanCount({
    currentCurrencyValue: currentCurrency,
    activeProductName: activeProductName,
    aggregatorId: state.ecosystemData.aggregator_id,
  });

  const {
    data: totalOutstandingLoans,
    status: totalOutstandingStatus,
    error: totalOutstandingError,
    isLoading: isLoadingOutstandingLoans,
  } = useTotalOutstandingLoans({
    currentCurrencyValue: currentCurrency,
    activeProductName: activeProductName,
    aggregator_id: state.ecosystemData.aggregator_id,
  });

  return (
    <div className="ManageUsers">
      {/*Ecosystem Selector*/}
      <div className="mt-20 ecosystem-selector speed-1x fadeInRight">
        <div className="datatable-wrapper">
          <div className="table-view">
            <div className="mt-20">
              <Filter view={onView} dataRecords={allAggregators} />
            </div>
          </div>
        </div>
      </div>

      {/*Datatable for Selected Ecosystem */}
      <div className="tabular-dataset hidden ">
        <div className="datatable-wrapper">
          {/*Welcome Block*/}
          <HeaderWithButton
            headerText={`${state.ecosystemName} Ecosystem`}
            buttonText="Back to all Aggregators"
            onClick={(e: any) => closeView(e)}
          />

          {/*Tabs*/}
          <Tabs tabs={state.tabs} onContentToggle={toggleTabContent} />

          {/*Admin Views Controlled by Tabs*/}
          {(() => {
            switch (state.activeTab) {
              case "finance":
                return (
                  <AggregatorFinancialStats
                    aggregatorDetails={state.ecosystemData}
                    currentCurrency={currentCurrency}
                    setCurrentCurrency={setCurrentCurrency}
                    activeProductName={activeProductName}
                    setActiveProductName={setActiveProductName}
                    productDetails={productDetails}
                    transactionsOverview={transactionsOverview}
                    trasactionOverviewStatus={trasactionOverviewStatus}
                    loanCount={loanCount}
                    loanCountStatus={loanCountStatus}
                    totalOutstandingLoans={totalOutstandingLoans}
                    totalOutstandingStatus={totalOutstandingStatus}
                    isLoadingOutstandingLoans={isLoadingOutstandingLoans}
                    isLoadingTransactions={isLoadingTransactions}
                    isLoadingLoanCount={isLoadingLoanCount}
                    isLoadingCurrencies={isLoadingCurrencies}
                    aggregatorCurrencies={
                      aggregatorCurrencies && aggregatorCurrencies?.data
                    }
                  />
                );
              case "agg-profile":
                return (
                  <AggregatorProfile
                    aggregatorDetails={currentAggregator.aggregatorDetails}
                    aggregator_id={state.ecosystemData.aggregator_id}
                    aggregatorProductTypes={state.aggregatorProductTypes}
                  />
                );

              case "funders":
                return (
                  <Funders
                    funderTableData={currentAggregator.funderTableData}
                    loading={currentAggregator.loading}
                    onModal={modalHandler}
                    showModal={props.showModal}
                    getAggregatorDrilldown={props.getAggregatorDrilldown}
                    aggregator_id={state.ecosystemData.aggregator_id}
                  />
                );

              case "borrowers":
                return (
                  <Partners
                    partnersTableData={currentAggregator.partnerTableData}
                    loading={currentAggregator.loading}
                    onModal={modalHandler}
                    showModal={props.showModal}
                    getAggregatorDrilldown={props.getAggregatorDrilldown}
                    aggregator_id={state.ecosystemData.aggregator_id}
                  />
                );
              case "settings":
                return (
                  <AggregatorProfileSettings
                    aggregator_id={state.ecosystemData.aggregator_id}
                  />
                );
              case "wallet":
                return (
                  <AllWallet aggregatorId={state.ecosystemData.aggregator_id} />
                );
              case "termsandconditions":
                return (
                  <TermsAndConditions
                    aggregator_id={state?.ecosystemData?.aggregator_id}
                  />
                );
              // case "aggregator-setting":
              //   return (
              //     <Settings
              //       aggregatorData={state.ecosystemData}
              //       onToast={props.setToast}
              //     />
              //   );

              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  // setToast,
  // showModal,
  // getAggregators,
  getAggregatorDrilldown,
  // getAggregatorStatistics,
  showModal,
})(withRouter(ManageUsers));
